import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Heading from '../../components/global/Heading';
import RichText from '../../components/global/RichText';
import Score from '../../components/Score';
import StyledModule from './module.style';
import getFeedbackSummary from '../../app/resources/feedbackSummary';

export const getServerSideProps = async () => {
  const [feedback] = await Promise.all([getFeedbackSummary()]);
  const finalScore = feedback.statistics.find(
    (a) => a.question_type === 'final_score'
  );
  const score = Number(finalScore.value);
  const maxScore = Number(finalScore.maxscore);
  return {
    props: {
      feedback: {
        score: (score / maxScore) * 10,
        maxScore: 10,
        count: Number(finalScore.count),
      },
    },
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const FeedbackModule = ({ title, feedbackText, feedback, ...p }) => {
  return (
    <StyledModule {...p} color="aliceBlue" xPaddingAsMargin>
      {!!title && <Heading title={title} />}
      {!!feedback && (
        <Score score={feedback.score} maxScore={feedback.maxScore} />
      )}
      {!!feedbackText && !!feedback && (
        <RichText
          className="subtext"
          content={feedbackText.content?.replace(
            '<!--COUNT-->',
            feedback.count
          )}
        />
      )}
    </StyledModule>
  );
};

export default FeedbackModule;

registerModule({ Feedback: Self });
